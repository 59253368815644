import { template as template_99869f84570f4ea68c43f425e2da379d } from "@ember/template-compiler";
import AnonymousSections from "./anonymous/sections";
import UserSections from "./user/sections";
const SidebarSections = template_99869f84570f4ea68c43f425e2da379d(`
  {{#if @currentUser}}
    <UserSections
      @collapsableSections={{@collapsableSections}}
      @panel={{@panel}}
      @hideApiSections={{@hideApiSections}}
    />
  {{else}}
    <AnonymousSections @collapsableSections={{@collapsableSections}} />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSections;
