import { template as template_5187cd10c4a24a988ad96e933dba422e } from "@ember/template-compiler";
const FKLabel = template_5187cd10c4a24a988ad96e933dba422e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
