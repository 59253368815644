import { template as template_0ac278835afc4d7fbc7eda3c5316a48d } from "@ember/template-compiler";
const FKText = template_0ac278835afc4d7fbc7eda3c5316a48d(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
