import { template as template_a28986252ba14759b477a8047cfefe56 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a28986252ba14759b477a8047cfefe56(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
