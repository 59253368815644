import { template as template_97f0fa3ebe0646219713a960bdb50dd9 } from "@ember/template-compiler";
const FKControlMenuContainer = template_97f0fa3ebe0646219713a960bdb50dd9(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
